<template>
  <v-dialog v-model="errorDialog" persistent max-width="800">
    <v-card>
      <v-card-title>
        {{ $t("Report problem") }}
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-textarea
              v-model="errorText"
              :label="$t('The problem')"
              :placeholder="$t('Describe the problem')"
              :hint="$t('When clicking the button... it fails to...')"
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="mt-4">
            <v-layout row justify-end>
              <v-btn text @click="$store.dispatch('setErrorDialog', false)">{{
                $t("Cancel")
              }}</v-btn>
              <v-btn
                @click="
                  $store.dispatch('reportErrorToDeveloper', {
                    errortext: errorText,
                    route: $route,
                  })
                "
                :loading="isReporting"
                :disabled="!errorText"
                class="ml-sm-4"
                @close="$store.dispatch('setErrorDialog', false)"
                >{{ $t("Send error to developers") }}</v-btn
              >
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      errorText: null,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      error: "error",
      errorDialog: "errorDialog",
      isReporting: "isReporting",
    }),
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>